<template>
  <div>
    <div v-if="data" class="row justify-center q-ma-md">
      <q-card class="col-xs-12 col-sm-3 q-ma-sm" style="width: 80%">
        <q-card-section class="q-pa-none">
          <div class="text-h6 text-center">
            {{ `${data.supplierCode} - ${data.supplierName}` }}
          </div>
        </q-card-section>
        <q-separator size="1px" color="black" />
        <q-card-section>
          <div class="col-12 row justify-between">
            <span class="text-bold">Empresa: </span>
            <span>{{ `${data.branch} - ${data.branchName}` }}</span>
          </div>
          <div class="col-12 row justify-between">
            <span class="text-bold">Título: </span>
            <span>{{ `${data.title}` }}</span>
          </div>
          <div class="col-12 row justify-between">
            <span class="text-bold">Tipo: </span>
            <span>{{ data.type }}</span>
          </div>
          <div class="col-12 row justify-between">
            <span class="text-bold">Parcela: </span>
            <span>{{ data.installment }}</span>
          </div>
          <div class="col-12 row justify-between">
            <span class="text-bold">Centro de Custo: </span>
            <span>{{ data.costCenter }}</span>
          </div>
          <div class="col-12 row justify-between">
            <span class="text-bold">Dt. Título: </span>
            <span>{{ formatStg(data.issue, "date") }}</span>
          </div>
          <div class="col-12 row justify-between text-red">
            <span class="text-bold">Vencimento: </span>
            <span>{{ formatStg(data.actualDueDate, "date") }}</span>
          </div>
          <div class="col-12 row text-subtitle1 text-bold justify-between">
            <span class="">Valor: </span>
            <span>{{ formatStg(data.amount, "currency") }}</span>
          </div>
          <div class="col-12 row justify-between">
            <span class="text-bold">Observação: </span>
            <span>{{ data.observation }}</span>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import formatString from "../../helpers/formatString";
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      required: false,
      default: "BRL"
    }
  },
  methods: {
    formatStg(value, type, decimals) {
      try {
        return formatString(value, type, decimals);
      } catch (err) {
        console.error("Erro formatação", err);
      }
    }
  },
  computed: {
    request() {
      const arrHierarchy = this.data.approvalHierarchy;
      var nameRequest;
      arrHierarchy.forEach(lvl => {
        if (lvl.level === "01") {
          nameRequest = lvl.approver;
        }
      });

      return nameRequest;
    }
  }
};
</script>

<style></style>
