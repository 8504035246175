<template>
  <div class="FinancialTitleDetail">
    <div class="row justify-center">
      <div class="col-auto">
        <q-btn-group
          :spread="isMobile"
          :class="{ 'fixed-bottom': isMobile, 'q-mt-md': !isMobile }"
          style="z-index:999;"
        >
          <q-btn icon="exit_to_app" class="buttonsBar" @click="exit" />
          <q-btn
            icon="account_tree"
            class="buttonsBar"
            @click="openHierarchy"
          />

          <q-btn icon="announcement" class="buttonsBar" @click="openDetails" />

          <q-btn
            icon="table_rows"
            class="buttonsBar"
            v-if="viewTable"
            @click="viewTable = !viewTable"
          />
          <q-btn
            v-else
            icon="table_chart"
            class="buttonsBar"
            @click="viewTable = !viewTable"
          />
        </q-btn-group>
      </div>
    </div>

    <q-scroll-area style="height: calc(100vh - 190px)">
      <div v-if="viewTable">
        <q-card class="q-ma-sm" v-if="financialtitle">
          <q-card-section>
            <div class="row justify-start">
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Título: </span>
                <span>{{ financialtitle.title }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Tipo: </span>
                <span>{{ financialtitle.type }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Empresa: </span>
                <span>{{
                  `${financialtitle.branch} - ${financialtitle.branchName}`
                }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Parcela: </span>
                <span>{{ financialtitle.installment }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Fornecedor: </span>
                <span>{{
                  `${financialtitle.supplierCode} - ${financialtitle.supplierName}`
                }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Dt. Título: </span>
                <span>{{ formatStg(financialtitle.issue, "date") }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2 text-red">
                <span class="text-bold">Vencimento: </span>
                <span>{{
                  formatStg(financialtitle.actualDueDate, "date")
                }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Centro de Custo: </span>
                <span>{{ financialtitle.costCenter.trim() }}</span>
              </div>
              <div
                class="col-xs-6 col-sm-6 col-md-4 col-lg-2 text-bold text-subtitle1"
              >
                <span class="">Valor: </span>
                <span>{{ formatStg(financialtitle.amount, "currency") }}</span>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
              <span class="text-bold">Observação: </span>
              <span>{{ financialtitle.observation }}</span>
            </div>
          </q-card-section>
        </q-card>
      </div>

      <div v-else>
        <productsCard :data="financialtitle" />
      </div>
    </q-scroll-area>

    <div class="row justify-evenly q-mb-xl">
      <div class="col-auto">
        <q-btn
          class="q-ma-xs"
          color="green"
          icon="check"
          label="Aprovar"
          @click="approve"
        />
      </div>
      <div class="col-auto">
        <q-btn
          class="q-ma-xs"
          color="red"
          icon="block"
          label="Rejeitar"
          @click="reject"
        />
      </div>
    </div>
    <ApprovalHierarchy ref="ApprovalHierarchy" />
    <ApprovalDetails ref="ApprovalDetails" />
    <JustifiedFinancialTitle ref="JustifiedFinancialTitle" />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import productsCard from "../approvalOfFinancialTitle/productsCard.vue";
import ApprovalHierarchy from "./ApprovalHierarchy";
import ApprovalDetails from "./ApprovalDetails.vue";
import JustifiedFinancialTitle from "./justifiedFinancialTitle.vue";
import formatString from "@/helpers/formatString";

export default {
  components: {
    productsCard,
    ApprovalHierarchy,
    ApprovalDetails,
    JustifiedFinancialTitle
  },
  data() {
    return {
      financialtitle: {},
      viewTable: false,
      columns: [
        {
          field: "title",
          label: "Título",
          align: "center",
          sortable: true
        },
        {
          field: "type",
          label: "Tipo",
          align: "center",
          sortable: true
        },
        {
          field: "branch",
          label: "Empresa",
          type: "string",
          align: "center",
          sortable: true
        },
        {
          field: "supplierName",
          label: "Fornecedor",
          type: "string",
          align: "center",
          sortable: true
        },
        {
          field: "issue",
          label: "Dt. Título",
          align: "string",
          type: "center",
          sortable: true
        },
        {
          field: "actualDueDate",
          label: "Validade",
          align: "center",
          type: "string",
          sortable: true
        },
        {
          field: "installment",
          label: "Parcela",
          align: "center",
          type: "string",
          sortable: true
        },
        {
          field: "costCenter",
          label: "Centro de Custo",
          align: "center",
          type: "string",
          sortable: true
        },
        {
          field: "observation",
          label: "Observação",
          align: "right",
          type: "string",
          sortable: true
        },
        {
          field: "amount",
          label: "Valor",
          align: "right",
          type: "currency",
          sortable: true
        }
      ]
    };
  },
  computed: {
    financialTitleId() {
      return this.$route.params.financialTitleId;
    },
    isMobile() {
      return this.$q.screen.xs;
    }
  },
  methods: {
    ...mapActions("financialtitle", [
      "getFinancialTitleById",
      "approvesRejectsJustifysFinancialTitles"
    ]),
    ...mapMutations("shared", ["setNotification"]),
    formatStg(value, type, decimals) {
      try {
        return formatString(value, type, decimals);
      } catch (err) {
        console.error("Erro formatação", err);
      }
    },
    exit() {
      this.$router.back();
    },
    openHierarchy() {
      this.$refs.ApprovalHierarchy.open(this.financialtitle.approvalHierarchy);
    },
    openDetails() {
      this.$refs.ApprovalDetails.open(this.financialtitle.observation);
    },
    approve() {
      this.sendResponse(
        this.financialtitle,
        "approved",
        "APROVAR",
        "text-green"
      );
    },
    reject() {
      this.sendResponse(
        this.financialtitle,
        "rejected",
        "REJEITAR",
        "text-red"
      );
    },
    sendResponse(request, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `Deseja realmente <span class="${classMessage}">${text}</span> o Título Financeiro ${request.title}?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          try {
            if (response === "rejected") {
              return this.$refs.JustifiedFinancialTitle.open(
                request,
                response,
                text,
                classMessage
              );
            }

            const reasonReject =
              response === "rejected" ? this.descriptionRejection : "approved";
            await this.approvesRejectsJustifysFinancialTitles({
              request,
              response,
              reasonReject
            });
            this.setNotification({
              message: "Resposta enviada com sucesso.",
              color: "green",
              position: "top"
            });
            this.$router.push({ name: "financialtitle" });
          } catch (error) {
            this.setNotification({
              message: error.message,
              color: "red",
              position: "top"
            });
          }
        });
    }
  },
  async created() {
    try {
      this.financialtitle = await this.getFinancialTitleById(
        this.financialTitleId
      );
    } catch (error) {
      console.log(error.message);
      this.$router.back();
    }
  }
};
</script>

<style lang="scss">
.FinancialTitleDetail {
  width: 100%;
  .buttonsBar {
    background: #11275b;
    color: #fff;
  }
}
</style>
