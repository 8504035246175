<template>
  <div class="ApprovalDetails">
    <q-dialog v-model="opened" class="q-pa-none">
      <q-card class="q-pa-none q-ma-none">
        <q-card-section class="text-center text-bold">
          <span class="text-h5">Detalhes do Título Financeiro</span>
        </q-card-section>
        <q-card-section class="q-pa-lg q-ma-none">
          <span class="">{{ financialTitleDetail }}</span>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn label="Fechar" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      opened: false,
      financialTitleDetail: ""
    };
  },
  methods: {
    open(financialTitleDetail) {
      console.log(financialTitleDetail);
      this.opened = true;
      this.financialTitleDetail = financialTitleDetail;
    }
  }
};
</script>
